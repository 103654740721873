import dayjs from 'dayjs'

import { HIT_SEARCH_PARAMS_URL, UserRole } from '@/constants'
import { App } from '@/types/app'
import { locales } from '@/lib/i18n'

export const formatPathname = (pathname: string) => {
    return pathname.substring(3)
}

export const comparePathname = (pathname: string, comparePathname: string) => {
    return formatPathname(pathname) === comparePathname
}

export const emailRegex = RegExp(/^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
export const phoneNumberRegex =
    /^$|(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

export const checkPermission = (
    roles: (UserRole | string)[] = [],
    acceptRoles: (UserRole | string)[] = []
) => {
    let accepted = false
    for (const acceptRole of acceptRoles) {
        if (roles.includes(acceptRole)) {
            accepted = true
            break
        }
    }
    return accepted
}

export interface GenAddressParams {
    streetNumber?: string
    streetAdditional?: string
    street?: string
    city?: string
    country?: string
    postcode?: string
}

export const genAddress = ({
    streetNumber,
    streetAdditional,
    street,
    city,
    country,
    postcode,
}: GenAddressParams) => {
    return `${streetNumber}, ${streetAdditional ? streetAdditional + ', ' : ''}${street}, ${postcode} ${city}${country ? `, ${country}` : ''}`
}

export interface GenAddressHitParams {
    street?: string
    provider?: string
    city?: string
    country?: string
}

export const genAddressHit = (params: GenAddressHitParams) => {
    return Object.values(params).join(', ')
}

export interface ItemHasId {
    '@id': string
    [key: string]: any
}

export const merArrayById = (array: any[], mergeArray: any[]) => {
    const setId = new Set((array as ItemHasId[]).map((item) => item['@id']))
    return [
        ...array,
        ...(mergeArray as ItemHasId[]).filter(
            (item) => !setId.has(item['@id'])
        ),
    ]
}

export const getDimensionImage = async (
    file: File
): Promise<{ width: number; height: number }> => {
    return await new Promise((resolve) => {
        const reader = new FileReader()
        reader.onload = (event) => {
            const img = new Image()
            img.onload = () => {
                resolve({ width: img.width, height: img.height })
            }
            img.src = (event.target?.result as string) ?? ''
        }
        reader.readAsDataURL(file)
    })
}

export const getIdFromPath = (path: string) =>
    path.substring(path.lastIndexOf('/') + 1)

interface CreateQueryString {
    name: string
    value: string
    searchParams?: string | URLSearchParams
    options?: {
        remove?: boolean
        append?: boolean
        removeKey?: boolean
    }
}

export const createQueryString = ({
    name,
    value,
    searchParams,
    options,
}: CreateQueryString) => {
    const params = new URLSearchParams(searchParams)
    if (options?.removeKey) {
        params.delete(name)
    } else if (options?.remove) {
        params.delete(name, value)
    } else {
        if (options?.append) {
            params.append(name, value)
        } else {
            params.set(name, value)
        }
    }

    return params.toString()
}

export const combineQueryString = (path: string, queryString: string) => {
    return path + '?' + queryString
}

export const formatDate = (date: dayjs.ConfigType) =>
    dayjs(date).format('YYYY-MM-DD')

export const formatDateTime = (date: dayjs.ConfigType) =>
    date ? dayjs(date).format('YYYY-MM-DD HH:mm') : ''

export const convertDomainToLtd = (domains: string[]) => {
    const tldSet = new Set(
        domains
            .filter((domain) => domain.lastIndexOf('.') >= 0)
            .map((domain) => domain.slice(domain.lastIndexOf('.') + 1))
    )

    return [...tldSet]
}

export const convertDateRangeString = (dateRange: string) => {
    const centerIndex = dateRange.indexOf('-')
    return {
        startDate: dayjs(dateRange.slice(0, centerIndex)).toISOString(),
        endDate: dayjs(dateRange.slice(centerIndex + 1)).toISOString(),
    }
}

export const createHitQueryString = (
    params: App.HitAndMatchSearchParams,
    exceptValue?: keyof typeof HIT_SEARCH_PARAMS_URL
) => {
    let query = ''

    const queryParams = new URLSearchParams()
    const { date, ...rest } = params

    if (date) {
        const { startDate, endDate } = convertDateRangeString(date)
        query = `foundAtFrom=${startDate}&foundAtTo=${endDate}`
    }

    Object.entries(rest).forEach(([key, value]) => {
        if (exceptValue === key) return
        if (value) {
            const ParamKey = HIT_SEARCH_PARAMS_URL[key]
            if (typeof value === 'string') {
                queryParams.append(ParamKey, value)
            } else {
                value.map((item) => queryParams.append(ParamKey, item))
            }
        }
    })

    if (
        queryParams.toString().includes('domain.name') &&
        exceptValue !== 'domain'
    ) {
        return `${queryParams.toString()}&${query}`
    } else {
        return `${queryParams.toString()}${query ? '&' + query : ''}`
    }
}
export const regexRoutes = (routes: string[], pathname: string) =>
    RegExp(
        `^(/(${locales.join('|')}))?(${routes
            .flatMap((p) => (p === '/' ? ['', '/'] : p))
            .join('|')})/?$`,
        'i'
    ).test(pathname)

export function formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const createReportQueryString = (params: App.ReportParams) => {
    const queryParams = new URLSearchParams()
    const defaultParams = {
        'order[createdAt]': App.SortOrderEnum.DESC,
    }
    let _params = Object.keys(params).length > 0 ? params : defaultParams
    Object.entries(_params).forEach(([key, value]) => {
        if (value) {
            if (typeof value === 'string') {
                queryParams.append(key, value)
            }
        }
    })
    return `${queryParams.toString()}`
}

export const createManageQueryString = (params: Record<string, any>) => {
    const queryParams = new URLSearchParams()

    Object.entries(params).forEach(([key, value]) => {
        if (value) {
            if (typeof value === 'string') {
                queryParams.append(key, value)
            }
        }
    })
    return `${queryParams.toString()}`
}

export const removeDuplicates = (strings: string[]): string[] => {
    return Array.from(new Set(strings))
}
