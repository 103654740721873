export const BRAND_LOCAL_KEY = 'BRAND'

export const LOCAL_STORAGE_KEY = {
    COLLAPSE_HIT: 'collapse_hit',
}

export const FETCH_TAG = {
    CUSTOMER_TAG: 'customer_tag',
    HIT_STATE: 'hit_state',
}

export enum UserRole {
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_OWNER = 'ROLE_OWNER',
    ROLE_USER = 'ROLE_USER',
}

export enum BrandUserRole {
    USER = 'ROLE_BRAND_USER',
    MANAGER = 'ROLE_BRAND_MANAGER',
}

export enum Country {
    DE = 'GERMANY',
    EN = 'ENGLAND',
}

export enum AssetType {
    COLLECTION = 'Collection',
    IMAGE = 'Image',
}

export enum AssetsStatus {
    ACTIVE = 'active',
    DEACTIVATED = 'deactivated',
    ARCHIVED = 'archived',
    DELETED = 'deleted',
    IMPORTING = 'importing',
}

export enum HitSelectOption {
    ALL = 'all',
    MISSING = 'missing',
    NO_MATCH = 'no-match',
    ILLEGAL = 'illegal',
    ALLOWLIST = 'allowlist',
    UNSURE = 'unsure',
}

export const HIT_SEARCH_PARAMS_URL: Record<string, string> = {
    select: 'classification[]',
    status: 'stateId[]',
    domain: 'domain.name[]',
    tld: 'domain.tld[]',
    tag: 'tags[]',
    country: 'domain.legalInfo.country[]',
    after: 'after[]',
    collection: 'brandImage.collection.name[]',
}
export const MAPBOX_TOKEN =
    'pk.eyJ1IjoiY2hpZW5wbjIzMDgiLCJhIjoiY2xqcDRrazdlMWw5aTNtbDVvZDBweGNuYiJ9.Gemew30IW1ogZXhIvxwHqQ'
export const MAPBOX_STYLE = 'mapbox://styles/mapbox/light-v9'

export const DOMAIN_REGEX = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,11}$/

export enum StatusEnum {
    DONE = 'done',
    REQUESTED = 'requested',
}

export const statusColor: Record<StatusEnum, string> = {
    [StatusEnum.DONE]: 'bg-success border-success text-white',
    [StatusEnum.REQUESTED]: 'bg-pending border-pending text-white',
}

export const ITEM_PER_PAGE = 30
