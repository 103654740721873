'use client'

import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useState } from 'react'
import { signIn } from 'next-auth/react'
import { useSearchParams } from 'next/navigation'
import { useTranslations } from 'next-intl'
import Link from 'next/link'

import { useRouter } from '@/lib/i18n'
import { FormInput } from '@/components/Base/Form'
import { emailRegex } from '@/utils'
import Button from '@/components/Base/Button'
import LocaleSwitcher from '@/components/Locale/LocaleSwitcher'

export default function LoginForm() {
    const router = useRouter()
    const searchParams = useSearchParams()
    const t = useTranslations('Login')
    const tCommon = useTranslations('Common')

    const [isLoading, setIsLoading] = useState(false)

    const formValidationSchema = () => {
        return yup.object().shape({
            username: yup
                .string()
                .matches(emailRegex, {
                    message: t('Error.invalid_email'),
                    excludeEmptyString: true,
                })
                .required(t('Error.required_email')),
            password: yup
                .string()
                .required(t('Error.required_password'))
                .min(8, t('Error.invalid_password')),
        })
    }

    const {
        register,
        handleSubmit,
        getValues,
        setError,
        formState: { errors },
    } = useForm({ resolver: yupResolver(formValidationSchema()) })

    const onFormSubmit = useCallback(() => {
        const values = getValues()
        // add or update values before calling parent submit handler

        setIsLoading(true)
        signIn('credentials', {
            ...values,
            redirect: false,
        }).then((result) => {
            if (result?.error) {
                setError('root.serverError', {
                    type: 'serverError',
                    message: t('Error.login_fail'),
                })
                setIsLoading(false)
            } else {
                const callbackUrl = searchParams.get('callbackUrl')
                if (callbackUrl) {
                    return router.push(callbackUrl)
                } else {
                    router.push('/dashboard')
                }
            }
        })
    }, [getValues, router, setError, searchParams, t])

    return (
        <div className='flex h-full flex-col'>
            <div className='flex justify-end'>
                <LocaleSwitcher />
            </div>
            <form
                action='/api/auth/callback/credentials'
                method='post'
                onSubmit={handleSubmit(onFormSubmit)}
                className='mx-auto my-auto w-full rounded-md bg-white px-5 py-8 shadow-md dark:bg-darkmode-600 sm:w-3/4 sm:px-8 lg:w-2/4 xl:ml-20 xl:w-auto xl:bg-transparent xl:p-0 xl:shadow-none'
            >
                <h2 className='intro-x text-center text-2xl font-bold xl:text-left xl:text-3xl'>
                    {t('title')}
                </h2>
                <div className='intro-x mt-2 flex flex-col text-center text-primary xl:hidden'>
                    <p>{t('message_start')}</p>
                    <p> {t('sub_message')}</p>
                </div>
                <div className='intro-x mt-8'>
                    <div>
                        <FormInput
                            type='text'
                            className='intro-x block min-w-full px-4 py-3 xl:min-w-[350px]'
                            placeholder={t('email')}
                            {...register('username')}
                        />
                        {errors.username && (
                            <div className='mt-2 text-danger'>
                                {errors.username.message}
                            </div>
                        )}
                    </div>
                    <div>
                        <FormInput
                            type='password'
                            className='intro-x mt-4 block min-w-full px-4 py-3 xl:min-w-[350px]'
                            placeholder={t('password')}
                            {...register('password')}
                        />
                        {errors.password && (
                            <div className='mt-2 text-danger'>
                                {errors.password.message}
                            </div>
                        )}
                    </div>
                    {errors.root?.serverError && (
                        <div className='mt-2 text-danger'>
                            {errors.root?.serverError.message}
                        </div>
                    )}
                </div>
                <div className='intro-x mt-4 flex text-xs text-slate-600 dark:text-slate-500 sm:text-sm'>
                    {/* <div className='mr-auto flex items-center'>
                        <FormCheck.Input
                            id='remember-me'
                            type='checkbox'
                            className='mr-2 border'
                        />
                        <label
                            className='cursor-pointer select-none'
                            htmlFor='remember-me'
                        >
                            Remember me
                        </label>
                    </div> */}
                    <Link href='/reset-password'>{t('forgot_password')}</Link>
                </div>
                <div className='intro-x mt-5 text-center xl:mt-8 xl:text-left'>
                    <Button
                        variant='primary'
                        className='w-full px-4 py-3 align-top xl:mr-3 xl:w-32'
                        type='submit'
                        disabled={isLoading}
                    >
                        {t('submit')}
                    </Button>
                </div>
                <div className='intro-x mt-10 text-center text-slate-600 dark:text-slate-500 xl:mt-24 xl:text-left'>
                    {tCommon('copyright')}
                </div>
            </form>
        </div>
    )
}
