export namespace App {
    export interface HitAndMatchSearchParams {
        status?: string[] | string
        select?: string[] | string
        domain?: string[] | string
        collection?: string
        tld?: string
        country?: string
        date?: string
        tag?: string[] | string
        after?: string[] | string
    }
    export interface ManageParams {
        page?: string
        search?: string
    }

    export enum SortOrderEnum {
        ASC = 'asc',
        DESC = 'desc',
    }
    export interface ReportParams {
        page?: string
        name?: string
        'order[size]'?: SortOrderEnum
        'order[domain.name]'?: SortOrderEnum
        'order[createAt]'?: SortOrderEnum
    }
    export interface GenerateMetadataProps {
        params: { locale: string; id?: string[] }
    }
}
